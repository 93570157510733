import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import AppHome from './components/AppHome.tsx';
import Formateurs from './components/GetFormateurs.tsx';
import Sessions from './components/GetSessions.tsx';
import UniqueSession from './components/GetUniqueSession.tsx';
import EditFacture from './components/HandleEditFactures.tsx';
import {QueryClient, QueryClientProvider} from "react-query";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_WORK;
axios.defaults.withCredentials = true


function App() {
  const queryClient = new QueryClient();

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/" element={<AppHome/>}/>
          <Route path="factures_modification/:id/:societe" element={<EditFacture/>}/>
          <Route path="formateurs/:societe" element={<Formateurs/>}/>
          <Route path="formateurs/:id/:societe" element={<Sessions/>}/>
          <Route path="formateurs/:idFor/session/:idSes/:societe"
                 element={<UniqueSession/>}/>
        </Routes>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
